import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // for google map places autocomplete
      address: "",

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},

      mapCenter: {
        lat: 25.07643,
        lng: 55.140504,
      },
    };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    this.setState({ address });
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]);
        try {
          var a = results[0]["geometry"]["location"];
          console.log({
            lat: a.lat(),
            lng: a.lng(),
          });
          this.setState({
            mapCenter: {
              lat: a.lat(),
              lng: a.lng(),
            },
          });
        } catch (error) {}
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const searchOptions = {
      types: ["establishment"],
    };
    return (
      <div id="googleMaps">
        <div id="all"></div>
        <div id="Name" style={{ display: "none" }}></div>
        <div id="PHONE" style={{ display: "none" }}></div>
        <div id="URL" style={{ display: "none" }}></div>
        <div id="Address" style={{ display: "none" }}></div>
        <div id="City" style={{ display: "none" }}></div>
        <div id="Country" style={{ display: "none" }}></div>
        <div id="Tags" style={{ display: "none" }}></div>
        <div id="Rate" style={{ display: "none" }}></div>
        <div id="Price" style={{ display: "none" }}></div>
        <div id="ZipCode" style={{ display: "none" }}></div>
        <div id="Reviews" style={{ display: "none" }}></div>
        <div id="DayOpenMonday" style={{ display: "none" }}></div>
        <div id="TimeOpenMonday" style={{ display: "none" }}></div>
        <div id="TimeCloseMonday" style={{ display: "none" }}></div>
        <div id="DayOpenTuesday" style={{ display: "none" }}></div>
        <div id="TimeOpenTuesday" style={{ display: "none" }}></div>
        <div id="TimeCloseTuesday" style={{ display: "none" }}></div>
        <div id="DayOpenWednesday" style={{ display: "none" }}></div>
        <div id="TimeOpenWednesday" style={{ display: "none" }}></div>
        <div id="TimeCloseWednesday" style={{ display: "none" }}></div>
        <div id="DayOpenThursday" style={{ display: "none" }}></div>
        <div id="TimeOpenThursday" style={{ display: "none" }}></div>
        <div id="TimeCloseThursday" style={{ display: "none" }}></div>
        <div id="DayOpenFriday" style={{ display: "none" }}></div>
        <div id="TimeOpenFriday" style={{ display: "none" }}></div>
        <div id="TimeCloseFriday" style={{ display: "none" }}></div>
        <div id="DayOpenSaturday" style={{ display: "none" }}></div>
        <div id="TimeOpenSaturday" style={{ display: "none" }}></div>
        <div id="TimeCloseSaturday" style={{ display: "none" }}></div>
        <div id="DayOpenSunday" style={{ display: "none" }}></div>
        <div id="TimeOpenSunday" style={{ display: "none" }}></div>
        <div id="TimeCloseSunday" style={{ display: "none" }}></div>
        <div id="test" style={{ display: "none" }}></div>
        <div id="map-canvas" className="d-none"></div>

        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              style={{ "z-index": "999", position: "absolute" }}
              className="serchfor"
            >
              <div className="searchforinput has-search">
                <span className="fas fa-search form-control-feedback">
                  {" "}
                  <FontAwesomeIcon icon={faSearch} />
                </span>

                <input
                  {...getInputProps({
                    placeholder: "Type your Business Name",
                    className: "location-search-input ",
                  })}
                  type="search"
                  id="BusinessName22"
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "suggestion-item--active px-3 pt-2"
                      : "suggestion-item px-3 pt-2";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span
                          onClick={() => {
                            initialize(suggestion.placeId);
                          }}
                        >
                          {suggestion.description}
                        </span>
                        {console.log(suggestion)}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <Map
          style={{
            height: "439.64px",
            borderRaduis: "15px",
            position: "relative",
          }}
          google={this.props.google}
          initialCenter={{
            lat: this.state?.mapCenter?.lat,
            lng: this.state?.mapCenter?.lng,
          }}
          center={{
            lat: this.state?.mapCenter?.lat,
            lng: this.state?.mapCenter?.lng,
          }}
          className="map-div"
        >
          <Marker
            position={{
              lat: this.state?.mapCenter?.lat,
              lng: this.state?.mapCenter?.lng,
            }}
          />
        </Map>
      </div>
    );
  }
}
function GetPlace(place, name) {
  try {
    return place.address_components.find((x) => x.types[0] === name)?.long_name;
  } catch (error) {}
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDib-4ZAgoExcPJmbEVmOQ_Fyk_tz7QY1w",
})(Maps);

export function initialize(placeId, rtn = false) {
  var google = window.google;
  var map = new google.maps.Map(document.getElementById("map-canvas"), {
    center: new google.maps.LatLng(0, 0),
    zoom: 15,
  });

  var service = new google.maps.places.PlacesService(map);
  return new Promise(function (resolve, reject) {
    service.getDetails(
      {
        placeId: placeId,
      },
      function (place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (document.getElementById("test"))
            document.getElementById("test").innerHTML = place.adr_address;
          window.MapInfo = {
            name: place.name,
            url: place.website,
            phone: place.formatted_phone_number,
            address: place.formatted_address,
            city: GetPlace(place, "locality"),
            zipcode: GetPlace(place, "postal_code"),
            country: GetPlace(place, "country"),
            tags: place.types,
            rate: place.rating,
            price: place.price_level,
            reviews: place.reviews?.length,
            dayOpenMonday: place?.opening_hours?.weekday_text[0]
              ?.replace(/ .*/, "")
              .replace(":", ""),
            timeOpenMonday: place?.opening_hours?.weekday_text[0]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseMonday: place?.opening_hours?.weekday_text[0]
              .split(": ")[1]
              .split(" – ")[1],
            dayOpenTuesday: place?.opening_hours?.weekday_text[1]
              ?.replace(/ .*/, "")
              .replace(":", ""),
            timeOpenTuesday: place?.opening_hours?.weekday_text[1]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseTuesday: place?.opening_hours?.weekday_text[1]
              .split(": ")[1]
              .split(" – ")[1],
            dayOpenWednesday: place?.opening_hours?.weekday_text[2]
              ?.replace(/ .*/, "")
              .replace(":", ""),
            timeOpenWednesday: place?.opening_hours?.weekday_text[2]
              ?.split(": ")[1]
              .split(" – ")[0],
            timeCloseWednesday: place?.opening_hours?.weekday_text[2]
              ?.split(": ")[1]
              .split(" – ")[1],
            dayOpenThursday: place?.opening_hours?.weekday_text[3]
              .replace(/ .*/, "")
              .replace(":", ""),
            timeOpenThursday: place?.opening_hours?.weekday_text[3]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseThursday: place?.opening_hours?.weekday_text[3]
              .split(": ")[1]
              .split(" – ")[1],
            dayOpenFriday: place?.opening_hours?.weekday_text[4]
              .replace(/ .*/, "")
              .replace(":", ""),
            timeOpenFriday: place?.opening_hours?.weekday_text[4]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseFriday: place?.opening_hours?.weekday_text[4]
              .split(": ")[1]
              .split(" – ")[1],
            dayOpenSaturday: place?.opening_hours?.weekday_text[5]
              .replace(/ .*/, "")
              .replace(":", ""),
            timeOpenSaturday: place?.opening_hours?.weekday_text[5]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseSaturday: place?.opening_hours?.weekday_text[5]
              .split(": ")[1]
              .split(" – ")[1],
            dayOpenSunday: place?.opening_hours?.weekday_text[6]
              .replace(/ .*/, "")
              .replace(":", ""),
            timeOpenSunday: place?.opening_hours?.weekday_text[6]
              .split(": ")[1]
              .split(" – ")[0],
            timeCloseSunday: place?.opening_hours?.weekday_text[6]
              .split(": ")[1]
              .split(" – ")[1],
          };

          if (rtn) {
            resolve(window.MapInfo);
            return;
          }

          document.getElementById("Name").innerText = place.name;
          document.getElementById("URL").innerText = place.website;
          document.getElementById("PHONE").innerText =
            place.formatted_phone_number;
          document.getElementById("Address").innerText =
            place.formatted_address;
          document.getElementById("City").innerText =
            place.address_components[2]?.long_name;
          document.getElementById("ZipCode").innerText =
            place?.address_components[6]?.long_name;
          document.getElementById("Country").innerText =
            place?.address_components[5]?.long_name;
          document.getElementById("Tags").innerText = place.types;
          document.getElementById("Rate").innerText = place.rating;
          document.getElementById("Price").innerText = place.price_level;
          document.getElementById("Reviews").innerText = place.reviews.length;
          document.getElementById("DayOpenMonday").innerText =
            place?.opening_hours?.weekday_text[0]
              ?.replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenMonday").innerText =
            place?.opening_hours?.weekday_text[0]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseMonday").innerText =
            place?.opening_hours?.weekday_text[0]
              .split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenTuesday").innerText =
            place?.opening_hours?.weekday_text[1]
              ?.replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenTuesday").innerText =
            place?.opening_hours?.weekday_text[1]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseTuesday").innerText =
            place?.opening_hours?.weekday_text[1]
              .split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenWednesday").innerText =
            place?.opening_hours?.weekday_text[2]
              ?.replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenWednesday").innerText =
            place?.opening_hours?.weekday_text[2]
              ?.split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseWednesday").innerText =
            place?.opening_hours?.weekday_text[2]
              ?.split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenThursday").innerText =
            place?.opening_hours?.weekday_text[3]
              .replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenThursday").innerText =
            place?.opening_hours?.weekday_text[3]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseThursday").innerText =
            place?.opening_hours?.weekday_text[3]
              .split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenFriday").innerText =
            place?.opening_hours?.weekday_text[4]
              .replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenFriday").innerText =
            place?.opening_hours?.weekday_text[4]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseFriday").innerText =
            place?.opening_hours?.weekday_text[4]
              .split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenSaturday").innerText =
            place?.opening_hours?.weekday_text[5]
              .replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenSaturday").innerText =
            place?.opening_hours?.weekday_text[5]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseSaturday").innerText =
            place?.opening_hours?.weekday_text[5]
              .split(": ")[1]
              .split(" – ")[1];
          document.getElementById("DayOpenSunday").innerText =
            place?.opening_hours?.weekday_text[6]
              .replace(/ .*/, "")
              .replace(":", "");
          document.getElementById("TimeOpenSunday").innerText =
            place?.opening_hours?.weekday_text[6]
              .split(": ")[1]
              .split(" – ")[0];
          document.getElementById("TimeCloseSunday").innerText =
            place?.opening_hours?.weekday_text[6]
              .split(": ")[1]
              .split(" – ")[1];
        }
      }
    );
  });
}
